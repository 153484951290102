
export function getBackendDomain() {
    if (window.location.hostname.includes('localhost') ||
        window.location.hostname.endsWith("lalami.me") ||
        window.location.hostname.endsWith("lalamime.com") ||
        window.location.hostname.endsWith("d3dbzxyywswxzm.cloudfront.net")
    ) {
        // if query contains parameter backend, use it
        const urlParams = new URLSearchParams(window.location.search);
        const backend = urlParams.get('backend');
        if (backend) {
            return backend;
        }
        return 'webrtc3.lalami.me';
    } else {
        return 'webrtc.train.peachnote.net';
    }
}

export function storeUserData(clientId: string, user: any) {
    const domain = getBackendDomain();
    return fetch('https://' + domain + '/store_user_info/' + clientId, {
        body: JSON.stringify(user),
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST'
    });
}