import React, {Component, createRef} from 'react';

import './App.css';
import IntroScreen from "./components/IntroScreen/IntroScreen";
import PermissionScreen from "./components/IntroScreen/PermissionScreen";
import {isMobile, isSafari} from "react-device-detect";
import {IGestureMatch} from "./IGestureMatch";
import {PlaybackState} from "./types/PlaybackState";
import {AppPages} from "./types/AppPages";
import ConnectionIndicators from "./components/ConnectionIndicators";
import WebrtcConnector from "./components/WebrtcConnector";
import MenuBar from "./components/MenuBar";
import SearchPage from "./components/SearchPage";
import TrainingPage from "./components/TrainingPage";

import TrainingRecordings from "./util/TrainingRecordings";

import { Amplify } from 'aws-amplify'
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import { CognitoUser } from "amazon-cognito-identity-js";

import { I18n } from 'aws-amplify/utils';


import config from './aws-exports'
import YoutubeContainer from "./components/PlayerContainer/YoutubePlayer/YoutubeContainer";
import AccountPage from "./components/AccountPage/AccountPage";
import { Authenticator } from '@aws-amplify/ui-react';
import IntroScreenTrain from "./components/IntroScreenTrain/IntroScreenTrain";
import {getBackendDomain, storeUserData} from "./util/Api";

import ControlButton from "./components/ControlButton";
import DevUI from "./components/DevUI/DevUI";
import ScoreUploadDialog from "./components/ScoreUploadDialog/ScoreUploadDialog";
import {uploadScore} from "./components/WebrtcConnector/WebrtcConnector";
import TempoSlider from "./components/TempoSlider/TempoSlider";


Amplify.configure(config);

interface AppState {
    currentScreen: AppPages  // enum
    userId?: string
    tosAccepted: boolean
    height: number
    width: number
    isWebView: boolean
    streamName: string
    gestureMatches: IGestureMatch[]
    gestureMatchTime: number
    currentYouTubeId?: string
    gestureMatchCurrentPosition?: number
    youtubePlaybackState: PlaybackState
    disconnectTime: number
    backendConnected: boolean
    faceRecognized: boolean
    audioMatched: boolean
    trainingPieceId: number
    trainingRecordingId: number
    trainingCurrentPosition: number
    isLoggedIn: boolean
    bitrate: number
    trainingMatchStartTime: number
    trainingMatchOffset: number
    trainingMatchId?: string
    firstContributionTime?: number
    totalTime: number
    monthTime: number
    weekTime: number
    dayTime: number
    isFetchingUserStats: boolean
    speechRecognitionResult: string
    dialogResponse: string
    tempo?: number
    isControlOn: boolean
    controlUpdateCallback?: ReturnType<typeof setInterval>
    showDevUI: boolean
    showScoreUploadDialog: boolean
    scoreFile: File | null
    hasShownHeadphoneNotice: boolean;
    currentPieceTitle?: string;
    appliedModifiers?: string[];
    systemStatus?: string;
    systemStatusDetails?: string;
    isVideoEnabled: boolean;
}

interface AppProps {

}

class App extends Component<AppProps, AppState> {

    webrtc: any;
    reportModeTimeout?: ReturnType<typeof setTimeout>;
    saveAudioMatchInterval: any;
    player: React.RefObject<YoutubeContainer>;

    constructor(props: AppProps) {
        super(props);
        this.state = {
            currentScreen: AppPages.INTRO,
            tosAccepted: false,
            userId: undefined,
            width: window.innerWidth,
            height: window.innerHeight,
            isWebView: window.navigator.userAgent.indexOf("LaLaMime") >= 0,
            streamName: '',
            gestureMatches: [],
            gestureMatchTime: 0,
            currentYouTubeId: undefined,
            youtubePlaybackState: PlaybackState.PAUSED,
            disconnectTime: 0,
            faceRecognized: false,
            audioMatched: false,
            backendConnected: false,
            trainingPieceId: -(-(localStorage.getItem('trainingPieceId') || 0)),
            trainingRecordingId: -(-(localStorage.getItem('trainingRecordingId') || 0)),
            trainingCurrentPosition: -1,
            isLoggedIn: false,
            bitrate: -1,
            trainingMatchStartTime: 0,
            trainingMatchOffset: 0,
            trainingMatchId: undefined,
            firstContributionTime: undefined,
            totalTime: 0,
            monthTime: 0,
            weekTime: 0,
            dayTime: 0,
            isFetchingUserStats: false,
            speechRecognitionResult: '',
            dialogResponse: '',
            isControlOn: false,
            controlUpdateCallback: undefined,
            showDevUI: new URLSearchParams(window.location.search).get('devui') === '1',
            showScoreUploadDialog: false,
            scoreFile: null,
            tempo: undefined,
            hasShownHeadphoneNotice: false,
            currentPieceTitle: '',
            appliedModifiers: [],
            systemStatus: '',
            systemStatusDetails: '',
            isVideoEnabled: false,
        };

        this.webrtc = createRef();
        this.player = createRef<YoutubeContainer>();
    }

    public componentDidMount(): void {

        this.updateDimensions();

        window.addEventListener('resize', this.updateDimensions.bind(this));
        window.addEventListener('orientationchange', this.updateDimensions.bind(this));

        fetchAuthSession()
            .then(({ credentials, identityId }) => {
                this.setState((prevState) => ({...prevState, userId: identityId }));
                this.fetchTime();

                getCurrentUser()
                    .then((user) => {
                        this.setState((prevState) => ({...prevState, isLoggedIn: true }));
                        identityId && storeUserData(identityId, user);
                    })
                    .catch(e => {
                        console.log('error: ', e);
                        this.setState((prevState) => ({...prevState, isLoggedIn: false }));
                    });
            })
            .catch(e => console.log('error: ', e));

        document.addEventListener('dragover', this.handleDragOver);
        document.addEventListener('drop', this.handleDrop);
    }

    public componentWillUnmount() {
        this.state.controlUpdateCallback && clearInterval(this.state.controlUpdateCallback);

        document.removeEventListener('dragover', this.handleDragOver);
        document.removeEventListener('drop', this.handleDrop);
    }

    public onStreamInfo(name: string, title?: string) {
        this.setState((prevState) => ({...prevState, streamName: title || name}))
    }

    public onMatch(match: IGestureMatch[]) {
        console.log('Got a match', match);
        match && match.length > 0 && this.setState((prevState) => ({...prevState, gestureMatches: match}));
    }

    public onAudioMatched(matched: boolean) {
        this.setState((prevState) => ({...prevState, audioMatched: matched}))
    }

    public onFaceRecognized(recognized: boolean) {
        this.setState((prevState) => ({...prevState, faceRecognized: recognized}))
    }

    public onBitrateReceived(bitrate: number) {
        this.setState((prevState) => ({...prevState,
            bitrate: bitrate
        }))
    }

    public onSpeechRecognitionResult(speech: string) {
        this.setState((prevState) => ({...prevState,
            speechRecognitionResult: speech
        }));
    }

    public onDialogResponse(response: string) {
        this.setState((prevState) => ({...prevState,
            dialogResponse: response || ''
        }));
    }

    public onTempoReceived(tempo: number) {
        console.log('Tempo received', tempo);
        this.setState((prevState) => ({...prevState, tempo: tempo}));
    }

    private closeHeadphoneNotice = () => {
        this.setState((prevState) => ({...prevState, hasShownHeadphoneNotice: true }));
    }

    public onConnect(isConnected: boolean) {
        if (!isConnected && this.state.backendConnected) {
            this.setState((prevState) => ({...prevState, disconnectTime: new Date().getTime()}));
        }
        this.setState((prevState) => ({...prevState, backendConnected: isConnected}))
    }

    public onDisconnect() {
        console.log('onDisconnect');
        if (this.state.currentScreen === AppPages.TRAINING) {
            if (this.state.trainingMatchId) {
                if (this.webrtc.current) {
                    let duration = new Date().getTime() / 1000 - this.state.trainingMatchStartTime;
                    console.log('onDisconnect with webrtc', this.webrtc.current);
                    this.webrtc.current.reportTrainingPlayback(
                        this.state.trainingMatchId,
                        this.state.trainingMatchStartTime,
                        this.state.trainingMatchOffset,
                        duration,
                        new Date().getTime() / 1000
                    )
                }
                this.setState((prevState) => ({...prevState,
                    youtubePlaybackState: PlaybackState.PAUSED,
                    trainingMatchId: undefined,
                    trainingMatchStartTime: -1
                }));
            }
        }
        this.setState((prevState) => ({...prevState,
            audioMatched: false,
            faceRecognized: false,
            backendConnected: false,
            disconnectTime: new Date().getTime(),
            bitrate: -1
        }))
    }

    private onPlayGestureMatch(youTubeId: string, requestTime: number, currentRecordingPosition: number) {
        console.log('onPlayGestureMatch', youTubeId, requestTime, currentRecordingPosition);
        this.setState((prevState) => ({...prevState,
            currentYouTubeId: youTubeId,
            gestureMatchTime: requestTime,
            gestureMatchCurrentPosition: currentRecordingPosition,
            youtubePlaybackState: PlaybackState.PLAYING
        }));
    }

    private updateDimensions(): void {

        let windowWidth = window.innerWidth;
        let windowHeight = window.innerHeight;

        if (isMobile && !isSafari) {
            windowWidth = document.documentElement ? document.documentElement.clientWidth : window.innerWidth;
            windowHeight = document.documentElement ? document.documentElement.clientHeight : window.innerHeight;
        }

        if (this.state.width !== windowWidth || this.state.height !== windowHeight) {
            this.setState((prevState) => ({...prevState,
                width: windowWidth,
                height: windowHeight
            }));
        }
    }

    private onTabChange(selected: AppPages) {
        // console.log('onTabChange', AppPages[selected]);
        try {
            this.reportModeChange(selected, 2);
        } catch (e) {
            console.error(e);
        }

        this.setState((prevState) => ({...prevState,
            currentScreen: selected
        }));
    }

    private reportModeChange(mode: AppPages, retryCount: number = 0) {
        // @ts-ignore
        clearTimeout(this.reportModeTimeout);
        if (this.webrtc.current && this.webrtc.current.dc && this.webrtc.current.dc.readyState === 'open') {
            this.webrtc.current.reportModeChange(AppPages[mode])
        } else if (retryCount > 0) {
            this.reportModeTimeout = setTimeout(() => this.reportModeChange(mode, retryCount - 1), 1000);
        } else {
            console.log("no reportModeChange anymore");
            if (this.webrtc.current && this.webrtc.current.dc) {
                console.log(this.webrtc.current.dc.readyState);
            }
        }
    }

    private handleDirectCommand(command: string) {
        if (this.webrtc.current) {
            this.webrtc.current.sendFreeText(command);
        }
    }

    private handleVolumeChange(direction: 'up' | 'down') {
        if (this.webrtc.current) {
            this.webrtc.current.sendPlaybackControl('change_volume', undefined, direction === 'up' ? 0.1 : -0.1);
        }
    }

    private handlePlayPause(action: 'play' | 'pause') {
        if (this.webrtc.current) {
            this.webrtc.current.sendPlaybackControl(action);
        }
    }

    private getNextTrainingRecording(nextPiece: boolean = false, storeOldValues: boolean = true):
        {
            trainingPieceId: number,
            trainingRecordingId: number,
            youtubeId: string
        } {

        localStorage.setItem('trainingPieceId', this.state.trainingPieceId + "");
        localStorage.setItem('trainingRecordingId', this.state.trainingRecordingId + "");

        let newPieceId: number;
        let newRecordingId: number;
        if (nextPiece) {
            newPieceId = this.state.trainingPieceId + 1;
            newRecordingId = 0;
        } else {
            newRecordingId = this.state.trainingRecordingId + 1;
            newPieceId = this.state.trainingPieceId;
            console.log('getNext', newPieceId, newRecordingId);
            if (newRecordingId >= TrainingRecordings[newPieceId].length) {
                newPieceId++;
                newRecordingId = 0;
            }
        }

        if (newPieceId >= TrainingRecordings.length) {
            newPieceId = 0;
            newRecordingId = 0;
        }

        return {
            trainingPieceId: newPieceId,
            trainingRecordingId: newRecordingId,
            youtubeId: TrainingRecordings[newPieceId][newRecordingId]
        };
    }

    private setPlaybackContext(playingRecordingId: string, recordingTime: number) {
        this.setState((prevState) => ({...prevState,
            trainingCurrentPosition: recordingTime
        }));
    }

    private onRecordingUnavailable(recordingId: string) {

    }

    private onYoutubePlayerStateChange(newState: PlaybackState) {
        console.log('onYoutubePlayerStateChange', newState, this.state.youtubePlaybackState);
        if (this.state.currentScreen === AppPages.TRAINING) {

            if (newState === PlaybackState.PLAYING) {
                this.setState((prevState) => ({...prevState,
                    trainingMatchId: this.state.currentYouTubeId,
                    trainingMatchStartTime: new Date().getTime() / 1000,
                    trainingMatchOffset: (this.player && this.player.current && this.player.current.getCurrentTime()) || 0,
                    youtubePlaybackState: newState
                }));
                clearInterval(this.saveAudioMatchInterval);
                this.saveAudioMatchInterval = setInterval(() => {
                    if (this.state.youtubePlaybackState === PlaybackState.PLAYING) {
                        this.reportTrainingPlayback()
                    }
                }, 5000)
            }

            if (newState === PlaybackState.PAUSED || newState === PlaybackState.BUFFERING) {
                clearInterval(this.saveAudioMatchInterval);
                this.reportTrainingPlayback();
                this.setState((prevState) => ({...prevState,
                    trainingMatchId: undefined,
                    trainingMatchStartTime: -1,
                    trainingMatchOffset: -1,
                    youtubePlaybackState: newState
                }));
            }
        }
    }

    private reportTrainingPlayback() {
        if (this.state.trainingMatchId) {
            if (this.webrtc.current && this.webrtc.current.dc && this.webrtc.current.dc.readyState === 'open' && this.player.current) {
                let duration = this.player.current.getCurrentTime() - this.state.trainingMatchOffset;
                if (duration === 0) {
                    return; // no sense in reporting empty intervals
                }
                this.webrtc.current.reportTrainingPlayback(
                    this.state.trainingMatchId,
                    this.state.trainingMatchStartTime,
                    this.state.trainingMatchOffset,
                    duration,
                    new Date().getTime() / 1000
                )
            } else {
                console.log("failed to report audio match");
            }
        }
    }

    private onShowPressed(isPressed: boolean) {
        if (isPressed) this.handleStartVideoCapture();
        else this.handleStopVideoCapture();

        this.onControlChanged(isPressed);
    }

    private onControlChanged(isPressed: boolean) {
        this.setState((prevState) => ({...prevState, isControlOn: isPressed}));

        if (isPressed) {
            this.state.controlUpdateCallback && clearInterval(this.state.controlUpdateCallback);
            let controlUpdateCallback = setInterval(() => {
                this.webrtc.current.reportControlState(isPressed);
            }, 1000);
            this.setState((prevState) => ({...prevState, controlUpdateCallback: controlUpdateCallback}));
        } else {
            this.state.controlUpdateCallback && clearInterval(this.state.controlUpdateCallback);
        }

        this.webrtc.current.reportControlState(isPressed);
    }

    private fetchTime() {

        if (!this.state.userId || this.state.isFetchingUserStats) {
            return;
        }

        console.log('Start fetching training times.');

        let domain = getBackendDomain();

        this.setState((prevState) => ({...prevState, isFetchingUserStats: true}));
        fetch('https://' + domain + '/time/' + this.state.userId, {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'GET'
        }).then((response) => {
            this.setState((prevState) => ({...prevState, isFetchingUserStats: false}));
            return response.json();
        }).then((json) => {
            console.log('Got training times', JSON.stringify(json));
            this.setState((prevState) => ({...prevState,
                firstContributionTime: json.first_contribution,
                totalTime: json.total,
                monthTime: json.month,
                weekTime: json.week,
                dayTime: json.today
            }))
        }).catch((reason: any) => {
            this.setState((prevState) => ({...prevState, isFetchingUserStats: false}));
            console.log(reason);
        });
    }

    handleDragOver = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    }

    handleDrop = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (this.state.currentScreen === AppPages.TRAINING && e.dataTransfer && e.dataTransfer.files) {
            const file = e.dataTransfer.files[0];
            if (file && (file.name.endsWith('.xml') ||
                file.name.endsWith('.musicxml') ||
                file.name.endsWith('.mxl')
            )) {
                this.setState((prevState) => ({...prevState, showScoreUploadDialog: true, scoreFile: file }));
            }
        }
    }

    handleScoreUpload = (scoreName: string) => {
        if (this.state.scoreFile && this.state.userId) {
            uploadScore(this.state.scoreFile, this.state.userId, scoreName);
        }
        this.setState((prevState) => ({...prevState, showScoreUploadDialog: false, scoreFile: null }));
    }

    handleTempoChange = (newTempo: number) => {
        this.setState((prevState) => ({...prevState, tempo: newTempo }));
        if (this.webrtc.current) {
            this.webrtc.current.sendPlaybackControl('change_tempo', undefined, newTempo);
        }
    };

    handleStartVideoCapture = () => {
        if (this.webrtc.current) {
            this.webrtc.current.startVideoCapture();
        }
        this.setState((prevState) => ({...prevState, isVideoEnabled: true }));
    };

    handleStopVideoCapture = () => {
        if (this.webrtc.current) {
            this.webrtc.current.stopVideoCapture();
        }
        this.setState((prevState) => ({...prevState, isVideoEnabled: false }));
    };

    onCurrentPieceReceived = (title: string, modifiers: string[]) => {
        this.setState((prevState) => ({...prevState,
            currentPieceTitle: title,
            appliedModifiers: modifiers,
        }));
    };

    onSystemStatusReceived = (status: string, details: string) => {
        this.setState((prevState) => ({...prevState,
            systemStatus: status,
            systemStatusDetails: details,
        }));
    };

    readFileAsBase64 = (file: File, scoreName: string) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            if (event.target && event.target.result) {
                const base64 = ("" + event.target.result).split(',')[1];
                this.webrtc.current.uploadScore(scoreName, file.name, base64);
            }
        };
        reader.readAsDataURL(file);
    }

    render() {

        let width;
        let height;
        let scale;
        if (this.state.width >= this.state.height) {
            width = Math.min(window.innerWidth, 640);
            const videoH = width * 480 / 640;
            height = Math.min(window.innerHeight, videoH);
            width = height * 640 / 480;
            scale = width / 640;
        } else {
            height = Math.min(window.innerHeight, 640);
            const videoW = height * 480 / 640;
            width = Math.min(window.innerWidth, videoW);
            height = width * 640 / 480;
            scale = height / 640;
        }

        const shouldShowMenuBar = [AppPages.SEARCH, /* AppPages.TRAINING, */ AppPages.ACCOUNT].includes(this.state.currentScreen);

        const shouldShowPlayer =
            this.state.currentYouTubeId && (this.state.currentScreen === AppPages.TRAINING ||
            this.state.youtubePlaybackState === PlaybackState.PLAYING);

        let player =
            <div style={{display: shouldShowPlayer ? 'block' : 'none'}}
                 onClick={() => {
                     this.setState((prevState) => ({...prevState,
                         currentYouTubeId: "",
                         youtubePlaybackState: PlaybackState.UNSTARTED
                     }));
                     this.player.current && this.player.current.pauseVideo();
                 }
                 }
            >
                <YoutubeContainer onYoutubePlayerStateChange={this.onYoutubePlayerStateChange.bind(this)}
                                  ref={this.player}
                />
            </div>;

        const showTrainIntro = window.location.hostname.endsWith("train.peachnote.net") ||
            window.location.hostname.endsWith("globalmusicbrain.com") ||
            window.location.hostname.endsWith("d2k6cfoykqf241.cloudfront.net");

        let mainScreen;

        switch (this.state.currentScreen) {
            case AppPages.INTRO:
                mainScreen = !showTrainIntro ?
                    <IntroScreen
                        onDone={() => {
                            this.setState((prevState) => ({...prevState, currentScreen: AppPages.TRAINING, tosAccepted: true}));
                        }}
                    /> :
                    <IntroScreenTrain
                        onDone={() => {
                            this.setState((prevState) => ({...prevState, currentScreen: AppPages.TRAINING, tosAccepted: true}));
                        }}
                    />
                ;
                break;
            case AppPages.PERMISSIONS:
                mainScreen =
                    <PermissionScreen
                        isWebView={this.state.isWebView}
                        onCancel={() => {
                            this.setState((prevState) => ({...prevState, currentScreen: AppPages.INTRO}))
                        }}
                        onDone={() => {
                            this.setState((prevState) => ({...prevState, currentScreen: AppPages.TRAINING}));
                        }}
                    />;
                break;
            case AppPages.SEARCH:
                mainScreen = <div className="Main-screen">
                    <SearchPage
                        matches={this.state.gestureMatches}
                        onPlayGestureMatch={this.onPlayGestureMatch.bind(this)}>
                        {player}
                    </SearchPage>
                </div>;
                break;

            case AppPages.TRAINING:
                mainScreen = (
                    <div className="Main-screen noselect">
                        {/* Display instructions */}
                        <div className="App-instruction noselect">
                            Press and hold the Show-Tell button or an affect above or the tempo slider to change the performance.
                        </div>

                        {/* Display current piece title */}
                        {this.state.currentPieceTitle && (
                            <div className="current-piece">
                                Now playing: <strong>{this.state.currentPieceTitle}</strong>
                            </div>
                        )}

                        {/* Display applied modifiers */}
                        {this.state.appliedModifiers && this.state.appliedModifiers.length > 0 && (
                            <div className="applied-modifiers">
                                Modifiers: {this.state.appliedModifiers.join(', ')}
                            </div>
                        )}

                        {/* Display system status */}
                        {this.state.systemStatus && (
                            <div className="system-status">
                                Status: {this.state.systemStatus}
                                {this.state.systemStatusDetails && ` - ${this.state.systemStatusDetails}`}
                            </div>
                        )}

                        {this.state.showDevUI && (
                            <DevUI
                                onDirectCommand={this.handleDirectCommand.bind(this)}
                                onVolumeChange={this.handleVolumeChange.bind(this)}
                                onPlayPause={this.handlePlayPause.bind(this)}
                            />
                        )}

                        {/* Control and tempo slider */}
                        <div className="control-tempo-container">
                            <div style={{minWidth: 120}}>

                                <div className="playback-controls">
                                    <button onClick={() => this.handlePlayPause('play')}>Play</button>
                                    <button onClick={() => this.handlePlayPause('pause')}>Pause</button>
                                </div>

                            </div>
                            <div className="control-button-wrapper">
                                <ControlButton
                                    onShowPressed={(touched) => this.onShowPressed(touched)}
                                    onTellPressed={(touched) => this.onControlChanged(touched)}
                                />
                            </div>
                            <TempoSlider
                                tempo={this.state.tempo}
                                onTempoChange={this.handleTempoChange}
                            />
                        </div>

                        {/* Headphone notice */}
                        {isMobile && !this.state.hasShownHeadphoneNotice && (
                            <div className="headphone-notice">
                                <p>For the best experience, please use headphones.</p>
                                <button onClick={this.closeHeadphoneNotice}>Got it</button>
                            </div>
                        )}
                    </div>
                );
                break;

            case AppPages.ACCOUNT:
                mainScreen = (
                    <div className="Main-screen" style={{overflowY: 'scroll'}}>
                        <Authenticator>
                            {({ signOut, user }) => (
                                <div>
                                    {user && (
                                        <AccountPage
                                            firstContributionTime={this.state.firstContributionTime}
                                            totalTime={this.state.totalTime}
                                            monthTime={this.state.monthTime}
                                            weekTime={this.state.weekTime}
                                            dayTime={this.state.dayTime}
                                            fetchTime={this.fetchTime.bind(this)}
                                        />
                                    )}
                                    <button onClick={signOut}>Sign out</button>
                                </div>
                            )}
                        </Authenticator>
                    </div>
                );
                break;
            default:
                mainScreen = (
                    <div className="Main-screen"/>
                )
                break;
        }

        return (
            <div className="App">
                {this.state.currentScreen !== AppPages.INTRO &&
                this.state.currentScreen !== AppPages.PERMISSIONS &&
                this.state.currentScreen !== AppPages.ACCOUNT &&
                <div style={{maxHeight: '46vh'}}>
                    {this.state.bitrate >= 0 &&
                    <div className={'App-bitrate'}>
                        {Math.round(this.state.bitrate / 1024)} kbps
                    </div>
                    }
                    <WebrtcConnector
                        userId={this.state.userId || 'us-east-1:dummy-user-id'}
                        backendConnected={this.state.backendConnected}
                        onStreamInfo={this.onStreamInfo.bind(this)}
                        onAudioMatched={this.onAudioMatched.bind(this)}
                        onFaceRecognized={this.onFaceRecognized.bind(this)}
                        onBitrateReceived={this.onBitrateReceived.bind(this)}
                        onSpeechRecognitionResultReceived={this.onSpeechRecognitionResult.bind(this)}
                        onDialogResponseReceived={this.onDialogResponse.bind(this)}
                        onTempoReceived={this.onTempoReceived.bind(this)}
                        onMatch={this.onMatch.bind(this)}
                        onConnect={this.onConnect.bind(this)}
                        onDisconnect={this.onDisconnect.bind(this)}
                        ref={this.webrtc}
                    />
                </div>
                }
                {this.state.currentScreen !== AppPages.INTRO &&
                this.state.currentScreen !== AppPages.PERMISSIONS &&
                this.state.currentScreen !== AppPages.ACCOUNT &&
                <header className="App-header">
                    <ConnectionIndicators
                        dialogMessage={this.state.dialogResponse}
                        backendConnected={this.state.backendConnected}
                        faceRecognized={this.state.faceRecognized}
                        audioRecognized={this.state.audioMatched
                        ||
                        ( // due to automatic match reporting from the YT player state
                            this.state.youtubePlaybackState === PlaybackState.PLAYING &&
                            this.state.currentScreen === AppPages.TRAINING
                        )}
                        controlEnabled={this.state.isControlOn}
                    />
                </header>
                }
                <div className="App-main">
                    {mainScreen}
                </div>

                <div style={{display: shouldShowMenuBar ? 'block' : 'none', position: 'fixed', bottom: 0}}>
                    <MenuBar activeTab={this.state.currentScreen}
                             onTabChange={this.onTabChange.bind(this)}
                             isLoggedIn={this.state.isLoggedIn}
                    />
                </div>

                {this.state.showScoreUploadDialog && (
                    <ScoreUploadDialog
                        fileName={this.state.scoreFile ? this.state.scoreFile.name : ''}
                        onSubmit={this.handleScoreUpload}
                        onCancel={() => this.setState((prevState) => ({...prevState, showScoreUploadDialog: false, scoreFile: null }))}
                    />
                )}
            </div>
        );
    }
}


const signUpConfig = {
    hideAllDefaults: true,
    signUpFields: [
        {
            label: I18n.get('Name'),
            key: 'name',
            required: true,
            placeholder: I18n.get('Your Name'),
            type: 'text',
            displayOrder: 1,
        },
        {
            label: I18n.get('Username'),
            key: 'username',
            required: true,
            placeholder: I18n.get('Username'),
            type: 'username',
            displayOrder: 2,
        },
        {
            label: I18n.get('Email'),
            key: 'email',
            required: true,
            placeholder: I18n.get('Email'),
            type: 'email',
            displayOrder: 3,
        },
        {
            label: I18n.get('Password'),
            key: 'password',
            required: true,
            placeholder: I18n.get('Password'),
            type: 'password',
            displayOrder: 4,
        },
    ],
};

export default App;
