import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {IntlProvider} from 'react-intl';
import ErrorBoundary from "./util/ErrorBoundary";

ReactDOM.render(
    <IntlProvider locale={'de'}>
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    </IntlProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

window.onresize = function() {
    // @ts-ignore
    document.body.height = window.innerHeight;
};
// @ts-ignore
window.onresize();