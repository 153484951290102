// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:e5c1513a-b006-4cfb-b715-a7045046cc55",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_L73UwDlxH",
    "aws_user_pools_web_client_id": "129ci3v2uo8hkdqt9e5hjkrqdj",
    "oauth": {}
};


export default awsmobile;
