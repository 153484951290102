// TempoSlider.tsx
import React, { useState, useRef, useEffect, useCallback } from 'react';
import './TempoSlider.css';

interface TempoSliderProps {
    tempo?: number;
    onTempoChange: (tempo: number) => void;
    minTempo?: number;
    maxTempo?: number;
}

const TempoSlider: React.FC<TempoSliderProps> = ({
                                                     tempo,
                                                     onTempoChange,
                                                     minTempo = 15,
                                                     maxTempo = 350
                                                 }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [currentTempo, setCurrentTempo] = useState(tempo || Math.sqrt(minTempo * maxTempo));
    const sliderRef = useRef<HTMLDivElement | null>(null);
    const currentTempoRef = useRef(currentTempo);
    const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);
    const updateIntervalRef = useRef<NodeJS.Timeout | null>(null);

    const log = useCallback((message: string, ...args: any[]) => {
        console.log(`[TempoSlider] ${message}`, ...args);
    }, []);

    useEffect(() => {
        log('Component mounted');
        return () => {
            log('Component unmounted');
            if (debounceTimerRef.current) {
                clearTimeout(debounceTimerRef.current);
            }
            if (updateIntervalRef.current) {
                clearInterval(updateIntervalRef.current);
            }
        };
    }, [log]);

    useEffect(() => {
        if (!isDragging && tempo !== undefined && tempo !== null) {
            const smoothingFactor = 0.2;
            const intervalId = setInterval(() => {
                const difference = tempo - currentTempoRef.current;
                if (Math.abs(difference) < 0.1) {
                    setCurrentTempo(tempo);
                    currentTempoRef.current = tempo;
                    clearInterval(intervalId);
                } else {
                    const newTempo = currentTempoRef.current + difference * smoothingFactor;
                    setCurrentTempo(newTempo);
                    currentTempoRef.current = newTempo;
                }
            }, 50);
            return () => clearInterval(intervalId);
        }
    }, [tempo, isDragging, log]);

    const debouncedTempoChange = useCallback((newTempo: number) => {
        if (debounceTimerRef.current) {
            clearTimeout(debounceTimerRef.current);
        }
        debounceTimerRef.current = setTimeout(() => {
            log('Debounced tempo change', { newTempo });
            onTempoChange(newTempo);
        }, 500);
    }, [onTempoChange, log]);

    const startPeriodicUpdate = useCallback(() => {
        if (updateIntervalRef.current) {
            clearInterval(updateIntervalRef.current);
        }
        updateIntervalRef.current = setInterval(() => {
            log('Periodic update', { currentTempo: currentTempoRef.current });
            onTempoChange(currentTempoRef.current);
        }, 1000);
    }, [onTempoChange, log]);

    const stopPeriodicUpdate = useCallback(() => {
        if (updateIntervalRef.current) {
            clearInterval(updateIntervalRef.current);
            updateIntervalRef.current = null;
        }
    }, []);

    const updateTempo = useCallback((clientY: number) => {
        if (sliderRef.current) {
            const rect = sliderRef.current.getBoundingClientRect();
            const height = rect.bottom - rect.top;
            const y = clientY - rect.top;
            const percentage = 1 - y / height;

            const minLog = Math.log(minTempo);
            const maxLog = Math.log(maxTempo);
            const newTempo = Math.exp(minLog + percentage * (maxLog - minLog));

            const clampedTempo = Math.max(minTempo, Math.min(maxTempo, newTempo));
            setCurrentTempo(clampedTempo);
            currentTempoRef.current = clampedTempo;
            debouncedTempoChange(clampedTempo);
            log('Tempo updated', { newTempo: clampedTempo });
        }
    }, [minTempo, maxTempo, debouncedTempoChange, log]);

    const handleInteractionStart = useCallback((clientY: number) => {
        setIsDragging(true);
        updateTempo(clientY);
        startPeriodicUpdate();
        log('Interaction started');
    }, [updateTempo, startPeriodicUpdate, log]);

    const handleInteractionMove = useCallback((clientY: number) => {
        if (isDragging) {
            updateTempo(clientY);
        }
    }, [isDragging, updateTempo]);

    const handleInteractionEnd = useCallback(() => {
        if (isDragging) {
            setIsDragging(false);
            stopPeriodicUpdate();
            if (debounceTimerRef.current) {
                clearTimeout(debounceTimerRef.current);
            }
            onTempoChange(currentTempoRef.current);
            log('Interaction ended');
        }
    }, [isDragging, stopPeriodicUpdate, onTempoChange, log]);

    const handleMouseDown = (e: React.MouseEvent) => {
        handleInteractionStart(e.clientY);
        e.preventDefault();
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        handleInteractionMove(e.clientY);
        e.preventDefault();
    };

    const handleTouchStart = (e: React.TouchEvent) => {
        handleInteractionStart(e.touches[0].clientY);
        e.preventDefault();
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        handleInteractionMove(e.touches[0].clientY);
        e.preventDefault();
    };

    const fillHeight = ((Math.log(currentTempo) - Math.log(minTempo)) / (Math.log(maxTempo) - Math.log(minTempo))) * 100;

    return (
        <div className="tempo-slider-container">
            <div className="tempo-slider-wrapper">
                <div
                    className="tempo-slider"
                    ref={sliderRef}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleInteractionEnd}
                    onMouseLeave={handleInteractionEnd}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleInteractionEnd}
                >
                    <div
                        className="tempo-slider-fill"
                        style={{ height: `${fillHeight}%` }}
                    />
                    <div
                        className="tempo-slider-handle"
                        style={{ bottom: `${fillHeight}%` }}
                    />
                </div>
                <div className="tempo-value">
                    {currentTempo ? Math.round(currentTempo) : "?"}<br/>BPM
                </div>
            </div>
        </div>
    );
};

export default TempoSlider;