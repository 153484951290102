import React, {Component} from 'react';
import './IntroScreen.css';
import StartButton from "../StartButton";

import {FormattedMessage} from "react-intl";


class IntroScreen extends Component<{onDone: () => void},  {}> {

    onClickStart() {
        this.props.onDone();
    }

    // TODO: implement flow based on https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia;
    // use its callbacks for updating the component state and ultimately calling this.props.isDone();
    // when available, rely on navigator.permissions for fast permissions checking.
    render() {

        const controls = <StartButton onClickStart={this.onClickStart.bind(this)}/>;

        return (
            <div className='Intro-main'>
                <div className='Intro-title'>
                    <div className='Title-text'>
                        LaLa<br/>
                        Mime
                    </div>
                </div>
                <div className='Intro-slogan'>
                    <FormattedMessage
                        id="app.slogan"
                        defaultMessage='Show the Music'
                    />
                </div>
                <div className='Intro-controls'>
                    {controls}
                </div>
                <div className='Intro-terms'>
                    <FormattedMessage
                        id="app.terms0"
                        defaultMessage="By using LaLaMime you agree to our {link1} and {link2}."
                        values={{
                            link1: <a href="https://www.iubenda.com/privacy-policy/8227276/full-legal" target="_blank" rel="noopener noreferer">Terms of Use</a>,
                            link2: <a href='https://www.iubenda.com/privacy-policy/8227276/full-legal' target='_blank' rel="noopener noreferer">Privacy Policy</a>
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default IntroScreen