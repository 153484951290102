import React, {Component} from "react";

import IAccountPageProperties from './IAccountPageProperties';
import './AccountPage.css'
import { I18n } from 'aws-amplify/utils';
import * as moment from 'moment'
import 'moment-duration-format';


class AccountPage extends Component<IAccountPageProperties, any> {

    componentDidMount(): void {
        this.props.fetchTime()
    }

    render() {
        return (
            <div className={'Account-page'}>

                <div>
                    <span>
                        {this.props.firstContributionTime
                            ? I18n.get('First contribution on') + ' ' +
                            moment.unix(this.props.firstContributionTime).format('LL')
                            : I18n.get('You haven\'t recorded anything yet.')
                        }
                    </span>
                </div>

                <div style={{alignSelf: 'center'}}>
                    <h2>Training</h2>

                    <div className={'stat'}>
                        <div className={'Account-label'}>{I18n.get('Today:')}</div>
                        <span>
                            {moment.duration(this.props.dayTime * 1000).format("h [hours], m [minutes]", {
                                largest: 2,
                                trim: "both"
                            })}
                        </span>
                    </div>

                    <div className={'stat'}>
                        <div className={'Account-label'}>{I18n.get('Week:')}</div>
                        <span>
                        {moment.duration(this.props.weekTime * 1000).format("h [hours], m [minutes]", {
                            largest: 2,
                            trim: "both"
                        })}
                        </span>
                    </div>

                    <div className={'stat'}>
                        <div className={'Account-label'}>{I18n.get('Month:')}</div>
                        <span>
                        {moment.duration(this.props.monthTime * 1000).format("h [hours], m [minutes]", {
                            largest: 2,
                            trim: "both"
                        })}
                        </span>
                    </div>

                    <div className={'stat'}>
                        <div className={'Account-label'}>{I18n.get('Total:')}</div>
                        <span>
                         {moment.duration(this.props.totalTime * 1000).format("h [hours], m [minutes]", {
                             largest: 2,
                             trim: "both"
                         })}
                        </span>
                    </div>
                </div>

                <div style={{alignSelf: 'center'}}>
                    <h2>Goals</h2>
                    <div className={'stat'}>
                        <div className={'Account-label'}>{I18n.get('Month:')}</div>
                        <span>
                            {moment.duration(20 * 4, 'hours').format("h [hours], m [minutes]", {
                                largest: 2,
                                trim: "both"
                            })}
                        </span>
                    </div>

                </div>
            </div>
        )
    }
}


export default AccountPage;