// DevUI.tsx
import React, { useState } from "react";
import './DevUI.css';

const DevUI: React.FC<{
    onDirectCommand: (command: string) => void;
    onVolumeChange: (direction: 'up' | 'down') => void;
    onPlayPause: (action: 'play' | 'pause') => void;
}> = ({ onDirectCommand, onVolumeChange, onPlayPause }) => {
    const [command, setCommand] = useState('');

    return (
        <div className="dev-ui">
            <input
                type="text"
                value={command}
                onChange={(e) => setCommand(e.target.value)}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        onDirectCommand(command);
                        setCommand('');
                    }
                }}
                placeholder="What or how to play"
            />
            <button onClick={() => onDirectCommand(command)}>Send</button>
            {false && <div className="volume-controls">
                <button onClick={() => onVolumeChange('up')}>Louder</button>
                <button onClick={() => onVolumeChange('down')}>Quieter</button>
            </div>}
            {false && <div className="playback-controls">
                <button onClick={() => onPlayPause('play')}>Play</button>
                <button onClick={() => onPlayPause('pause')}>Pause</button>
            </div>}
        </div>
    );
};

export default DevUI;