import React, {useState, useEffect, useRef, useCallback} from 'react';
import * as d3 from 'd3';

const emotionsList: string[] = [
    'anger', 'anxious', 'calm', 'capricious', 'comical', 'decisive', 'depressed', 'dreamy',
    'elegant', 'enthusiastic', 'fierce', 'gentle', 'happy', 'harsh', 'heavy', 'impetuous',
    'important', 'kind', 'longing', 'marching', 'melancholic', 'melodious', 'mysterious',
    'nostalgia', 'passionately', 'rapidly', 'reflective', 'religious', 'sad', 'sincere',
    'sleepy', 'solemn', 'triumphantly'
];

interface Translations {
    [key: string]: {
        [key: string]: string;
    };
}

// Translations for the emotion labels
const translations: Translations = {
    en: {
        'anger': 'Angrily',
        'anxious': 'Anxiously',
        'calm': 'Calmly',
        'capricious': 'Capriciously',
        'comical': 'Comically',
        'decisive': 'Decisively',
        'depressed': 'Depressingly',
        'dreamy': 'Dreamily',
        'elegant': 'Elegantly',
        'enthusiastic': 'Enthusiastically',
        'fierce': 'Fiercely',
        'gentle': 'Gently',
        'happy': 'Happily',
        'harsh': 'Harshly',
        'heavy': 'Heavily',
        'impetuous': 'Impetuously',
        'important': 'Importantly',
        'kind': 'Kindly',
        'longing': 'Longingly',
        'marching': 'Marchingly',
        'melancholic': 'Melancholically',
        'melodious': 'Melodiously',
        'mysterious': 'Mysteriously',
        'nostalgia': 'Nostalgically',
        'passionately': 'Passionately',
        'rapidly': 'Rapidly',
        'reflective': 'Reflectively',
        'religious': 'Religiously',
        'sad': 'Sadly',
        'sincere': 'Sincerely',
        'sleepy': 'Sleepily',
        'solemn': 'Solemnly',
        'triumphantly': 'Triumphantly',
    },
    de: {
        'anger': 'Zornig',
        'anxious': 'Unruhig',
        'calm': 'Ruhig',
        'capricious': 'Kapriziös',
        'comical': 'Humorvoll',
        'decisive': 'Bestimmt',
        'depressed': 'Niedergeschlagen',
        'dreamy': 'Verträumt',
        'elegant': 'Elegant',
        'enthusiastic': 'Begeistert',
        'fierce': 'Wild',
        'gentle': 'Sanft',
        'happy': 'Fröhlich',
        'harsh': 'Schroff',
        'heavy': 'Wuchtig',
        'impetuous': 'Stürmisch',
        'important': 'Bedeutungsvoll',
        'kind': 'Lieblich',
        'longing': 'Sehnsüchtig',
        'marching': 'Marschierend',
        'melancholic': 'Schwermütig',
        'melodious': 'Wohlklingend',
        'mysterious': 'Geheimnisvoll',
        'nostalgia': 'Nostalgisch',
        'passionately': 'Leidenschaftlich',
        'rapidly': 'Rasant',
        'reflective': 'Besinnlich',
        'religious': 'Andächtig',
        'sad': 'Traurig',
        'sincere': 'Aufrichtig',
        'sleepy': 'Schläfrig',
        'solemn': 'Feierlich',
        'triumphantly': 'Triumphal',
    },
    uk: {
        'anger': 'Гнівно',
        'anxious': 'Тривожно',
        'calm': 'Спокійно',
        'capricious': 'Примхливо',
        'comical': 'Комічно',
        'decisive': 'Рішуче',
        'depressed': 'Пригнічено',
        'dreamy': 'Мрійливо',
        'elegant': 'Вишукано',
        'enthusiastic': 'Натхненно',
        'fierce': 'Люто',
        'gentle': 'Ніжно',
        'happy': 'Радісно',
        'harsh': 'Різко',
        'heavy': 'Важко',
        'impetuous': 'Стрімко',
        'important': 'Значуще',
        'kind': 'Лагідно',
        'longing': 'Тужливо',
        'marching': 'Маршово',
        'melancholic': 'Меланхолійно',
        'melodious': 'Мелодійно',
        'mysterious': 'Таємничо',
        'nostalgia': 'Ностальгійно',
        'passionately': 'Пристрасно',
        'rapidly': 'Швидко',
        'reflective': 'Задумливо',
        'religious': 'Побожно',
        'sad': 'Сумно',
        'sincere': 'Щиро',
        'sleepy': 'Сонливо',
        'solemn': 'Урочисто',
        'triumphantly': 'Тріумфально',
    },
    ru: {
        'anger': 'Гневно',
        'anxious': 'Тревожно',
        'calm': 'Спокойно',
        'capricious': 'Капризно',
        'comical': 'Комично',
        'decisive': 'Решительно',
        'depressed': 'Подавленно',
        'dreamy': 'Мечтательно',
        'elegant': 'Изящно',
        'enthusiastic': 'Воодушевленно',
        'fierce': 'Яростно',
        'gentle': 'Нежно',
        'happy': 'Радостно',
        'harsh': 'Резко',
        'heavy': 'Тяжело',
        'impetuous': 'Стремительно',
        'important': 'Значительно',
        'kind': 'Ласково',
        'longing': 'Тоскливо',
        'marching': 'Маршево',
        'melancholic': 'Меланхолично',
        'melodious': 'Мелодично',
        'mysterious': 'Загадочно',
        'nostalgia': 'Ностальгично',
        'passionately': 'Страстно',
        'rapidly': 'Быстро',
        'reflective': 'Задумчиво',
        'religious': 'Благоговейно',
        'sad': 'Грустно',
        'sincere': 'Искренне',
        'sleepy': 'Сонно',
        'solemn': 'Торжественно',
        'triumphantly': 'Триумфально',
    }
};

interface Link extends d3.SimulationLinkDatum<EmotionNode> {
    source: string | EmotionNode;
    target: string | EmotionNode;
    value: number;
}

const links: Link[] = [
    {source: 'anger', target: 'fierce', value: 0.7},
    {source: 'anger', target: 'harsh', value: 0.5},
    {source: 'anger', target: 'impetuous', value: 0.3},
    {source: 'anger', target: 'passionately', value: 0.2},
    {source: 'anxious', target: 'depressed', value: 0.3},
    {source: 'anxious', target: 'impetuous', value: 0.2},
    {source: 'anxious', target: 'sad', value: 0.4},
    {source: 'anxious', target: 'rapidly', value: 0.3},
    {source: 'calm', target: 'gentle', value: 0.6},
    {source: 'calm', target: 'reflective', value: 0.4},
    {source: 'calm', target: 'sleepy', value: 0.3},
    {source: 'calm', target: 'religious', value: 0.2},
    {source: 'capricious', target: 'impetuous', value: 0.5},
    {source: 'capricious', target: 'comical', value: 0.3},
    {source: 'capricious', target: 'rapidly', value: 0.4},
    {source: 'capricious', target: 'enthusiastic', value: 0.2},
    {source: 'comical', target: 'happy', value: 0.4},
    {source: 'comical', target: 'enthusiastic', value: 0.3},
    {source: 'comical', target: 'capricious', value: 0.3},
    {source: 'comical', target: 'gentle', value: 0.2},
    {source: 'decisive', target: 'important', value: 0.5},
    {source: 'decisive', target: 'triumphantly', value: 0.4},
    {source: 'decisive', target: 'marching', value: 0.3},
    {source: 'decisive', target: 'fierce', value: 0.2},
    {source: 'depressed', target: 'sad', value: 0.6},
    {source: 'depressed', target: 'melancholic', value: 0.5},
    {source: 'depressed', target: 'anxious', value: 0.3},
    {source: 'depressed', target: 'heavy', value: 0.2},
    {source: 'dreamy', target: 'mysterious', value: 0.5},
    {source: 'dreamy', target: 'reflective', value: 0.4},
    {source: 'dreamy', target: 'sleepy', value: 0.3},
    {source: 'dreamy', target: 'melodious', value: 0.2},
    {source: 'elegant', target: 'important', value: 0.4},
    {source: 'elegant', target: 'gentle', value: 0.3},
    {source: 'elegant', target: 'melodious', value: 0.3},
    {source: 'elegant', target: 'reflective', value: 0.2},
    {source: 'enthusiastic', target: 'happy', value: 0.6},
    {source: 'enthusiastic', target: 'passionately', value: 0.5},
    {source: 'enthusiastic', target: 'rapidly', value: 0.3},
    {source: 'enthusiastic', target: 'triumphantly', value: 0.4},
    {source: 'fierce', target: 'harsh', value: 0.5},
    {source: 'fierce', target: 'impetuous', value: 0.4},
    {source: 'fierce', target: 'passionately', value: 0.3},
    {source: 'fierce', target: 'decisive', value: 0.2},
    {source: 'gentle', target: 'kind', value: 0.6},
    {source: 'gentle', target: 'calm', value: 0.5},
    {source: 'gentle', target: 'melodious', value: 0.3},
    {source: 'gentle', target: 'sincere', value: 0.4},
    {source: 'happy', target: 'enthusiastic', value: 0.6},
    {source: 'happy', target: 'triumphantly', value: 0.4},
    {source: 'happy', target: 'comical', value: 0.3},
    {source: 'happy', target: 'passionately', value: 0.3},
    {source: 'harsh', target: 'anger', value: 0.5},
    {source: 'harsh', target: 'fierce', value: 0.4},
    {source: 'harsh', target: 'heavy', value: 0.3},
    {source: 'harsh', target: 'impetuous', value: 0.2},
    {source: 'heavy', target: 'solemn', value: 0.4},
    {source: 'heavy', target: 'important', value: 0.3},
    {source: 'heavy', target: 'harsh', value: 0.3},
    {source: 'heavy', target: 'depressed', value: 0.2},
    {source: 'impetuous', target: 'rapidly', value: 0.5},
    {source: 'impetuous', target: 'capricious', value: 0.4},
    {source: 'impetuous', target: 'fierce', value: 0.3},
    {source: 'impetuous', target: 'passionately', value: 0.3},
    {source: 'important', target: 'decisive', value: 0.5},
    {source: 'important', target: 'solemn', value: 0.3},
    {source: 'important', target: 'elegant', value: 0.3},
    {source: 'important', target: 'religious', value: 0.2},
    {source: 'kind', target: 'gentle', value: 0.6},
    {source: 'kind', target: 'sincere', value: 0.5},
    {source: 'kind', target: 'calm', value: 0.3},
    {source: 'kind', target: 'happy', value: 0.2},
    {source: 'longing', target: 'nostalgia', value: 0.7},
    {source: 'longing', target: 'melancholic', value: 0.5},
    {source: 'longing', target: 'sad', value: 0.4},
    {source: 'longing', target: 'reflective', value: 0.3},
    {source: 'marching', target: 'triumphantly', value: 0.5},
    {source: 'marching', target: 'decisive', value: 0.4},
    {source: 'marching', target: 'important', value: 0.3},
    {source: 'marching', target: 'rapidly', value: 0.2},
    {source: 'melancholic', target: 'sad', value: 0.6},
    {source: 'melancholic', target: 'nostalgia', value: 0.5},
    {source: 'melancholic', target: 'longing', value: 0.4},
    {source: 'melancholic', target: 'reflective', value: 0.3},
    {source: 'melodious', target: 'passionately', value: 0.4},
    {source: 'melodious', target: 'dreamy', value: 0.3},
    {source: 'melodious', target: 'gentle', value: 0.3},
    {source: 'melodious', target: 'elegant', value: 0.2},
    {source: 'mysterious', target: 'dreamy', value: 0.5},
    {source: 'mysterious', target: 'reflective', value: 0.4},
    {source: 'mysterious', target: 'solemn', value: 0.3},
    {source: 'mysterious', target: 'religious', value: 0.2},
    {source: 'nostalgia', target: 'longing', value: 0.7},
    {source: 'nostalgia', target: 'melancholic', value: 0.5},
    {source: 'nostalgia', target: 'reflective', value: 0.4},
    {source: 'nostalgia', target: 'sad', value: 0.3},
    {source: 'passionately', target: 'enthusiastic', value: 0.5},
    {source: 'passionately', target: 'fierce', value: 0.3},
    {source: 'passionately', target: 'melodious', value: 0.3},
    {source: 'passionately', target: 'rapidly', value: 0.2},
    {source: 'rapidly', target: 'impetuous', value: 0.5},
    {source: 'rapidly', target: 'enthusiastic', value: 0.3},
    {source: 'rapidly', target: 'capricious', value: 0.3},
    {source: 'rapidly', target: 'anxious', value: 0.2},
    {source: 'reflective', target: 'calm', value: 0.4},
    {source: 'reflective', target: 'mysterious', value: 0.3},
    {source: 'reflective', target: 'melancholic', value: 0.3},
    {source: 'reflective', target: 'nostalgia', value: 0.2},
    {source: 'religious', target: 'solemn', value: 0.5},
    {source: 'religious', target: 'important', value: 0.3},
    {source: 'religious', target: 'mysterious', value: 0.2},
    {source: 'religious', target: 'calm', value: 0.2},
    {source: 'sad', target: 'depressed', value: 0.6},
    {source: 'sad', target: 'melancholic', value: 0.5},
    {source: 'sad', target: 'longing', value: 0.4},
    {source: 'sad', target: 'anxious', value: 0.3},
    {source: 'sincere', target: 'kind', value: 0.5},
    {source: 'sincere', target: 'gentle', value: 0.4},
    {source: 'sincere', target: 'calm', value: 0.3},
    {source: 'sincere', target: 'important', value: 0.2},
    {source: 'sleepy', target: 'calm', value: 0.3},
    {source: 'sleepy', target: 'dreamy', value: 0.2},
    {source: 'sleepy', target: 'gentle', value: 0.2},
    {source: 'sleepy', target: 'melancholic', value: 0.1},
    {source: 'solemn', target: 'religious', value: 0.5},
    {source: 'solemn', target: 'heavy', value: 0.4},
    {source: 'solemn', target: 'important', value: 0.3},
    {source: 'solemn', target: 'mysterious', value: 0.2},
    {source: 'triumphantly', target: 'happy', value: 0.5},
    {source: 'triumphantly', target: 'marching', value: 0.4},
    {source: 'triumphantly', target: 'enthusiastic', value: 0.4},
    {source: 'triumphantly', target: 'decisive', value: 0.3},
];

// Simple deterministic random number generator
function mulberry32(a: number): () => number {
    return function () {
        let t = a += 0x6D2B79F5;
        t = Math.imul(t ^ t >>> 15, t | 1);
        t ^= t + Math.imul(t ^ t >>> 7, t | 61);
        return ((t ^ t >>> 14) >>> 0) / 4294967296;
    }
}

type Timeout = ReturnType<typeof setTimeout>;

interface EmotionVoronoiProps {
    emotionalResponse: { [key: string]: number };
    onEmotionSelected: (emotion: string) => void;
}

interface Emotion {
    label: string;
    x: number;
    y: number;
    activation: number;
}

interface Dimensions {
    width: number;
    height: number;
}

interface HexPosition {
    q: number;
    r: number;
    x: number;
    y: number;
}

interface SimulationNode extends d3.SimulationNodeDatum {
    id: string;
}

interface EmotionNode extends d3.SimulationNodeDatum {
    id: string;
    x: number;
    y: number;
}

// Helper functions


function getTextWidth(text: string, fontSize: number = 12): number {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) {
        console.warn('2D context not supported');
        return 50; // default width
    }
    context.font = `${fontSize}px Arial`;
    return context.measureText(text).width;
}

function getColor(activation: number): string {
    const colorValue = Math.round(255 - activation * 255);
    return `rgb(${colorValue}, 255, ${colorValue})`;
}

function EmotionVoronoi({emotionalResponse, onEmotionSelected}: EmotionVoronoiProps) {
    const [emotions, setEmotions] = useState<Emotion[]>([]);
    const [dimensions, setDimensions] = useState<Dimensions>({
        width: 0,
        height: 0
    });
    const [activeEmotion, setActiveEmotion] = useState<string | null>(null);
    const [pressedEmotion, setPressedEmotion] = useState<string | null>(null);
    const [animatingEmotions, setAnimatingEmotions] = useState<Set<string>>(new Set());
    const animationRef = useRef<number | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const dimensionsRef = useRef<Dimensions>({ width: 0, height: 0 });
    const callbackIntervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

    const simulationRef = useRef<d3.Simulation<EmotionNode, undefined> | null>(null);
    const padding = 40;
    const fontSize = 12;

    type SupportedLanguage = 'en' | 'de' | 'uk' | 'ru';

    const [language, setLanguage] = useState<SupportedLanguage>(() => {
        const lang = navigator.language || 'en';
        if (lang.startsWith('de')) return 'de';
        if (lang.startsWith('uk')) return 'uk';
        if (lang.startsWith('ru')) return 'ru';
        return 'en';
    });

    function getTranslatedLabel(label: string): string {
        return translations[language]?.[label] || translations['en']?.[label] || label;
    }

    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                const newDimensions = {
                    width: containerRef.current.clientWidth,
                    height: containerRef.current.clientHeight
                };
                if (newDimensions.width !== dimensionsRef.current.width ||
                    newDimensions.height !== dimensionsRef.current.height) {
                    setDimensions(newDimensions);
                    dimensionsRef.current = newDimensions;
                }
            }
        };

        // Call handleResize immediately
        handleResize();

        // Set up a ResizeObserver to watch for container size changes
        const resizeObserver = new ResizeObserver(handleResize);
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        // Clean up
        return () => {
            if (containerRef.current) {
                resizeObserver.unobserve(containerRef.current);
            }
        };
    }, []);


    useEffect(() => {
        const preventContextMenu = (e: Event) => {
            e.preventDefault();
        };

        document.addEventListener('contextmenu', preventContextMenu);

        return () => {
            document.removeEventListener('contextmenu', preventContextMenu);
        };
    }, []);

    const calculateLayout = useCallback(() => {
        const seed = 123; // Fixed seed for deterministic layout
        const rng = mulberry32(seed);

        const nodes: EmotionNode[] = emotionsList.map(emotion => ({
            id: emotion,
            x: rng() * (dimensions.width - 2 * padding) + padding,
            y: rng() * (dimensions.height - 2 * padding) + padding
        }));

        const simulation = d3.forceSimulation<EmotionNode>(nodes)
            .force('link', d3.forceLink<EmotionNode, Link>(links).id(d => d.id).strength(l => l.value * 0.1))
            .force('charge', d3.forceManyBody().strength(-100))
            .force('collision', d3.forceCollide<EmotionNode>().radius(d => {
                const textWidth = getTextWidth(getTranslatedLabel(d.id), fontSize);
                const baseRadius = Math.sqrt((dimensions.width * dimensions.height) / (emotionsList.length * Math.PI)) * 0.5;
                return Math.max(textWidth / 2 + 20, baseRadius);
            }))
            .force('center', d3.forceCenter(dimensions.width / 2, dimensions.height / 2))
            .force('boundary', () => {
                nodes.forEach(node => {
                    node.x = Math.max(padding, Math.min(dimensions.width - padding, node.x || 0));
                    node.y = Math.max(padding, Math.min(dimensions.height - padding, node.y || 0));
                });
            });

        for (let i = 0; i < 300; ++i) simulation.tick();

        const optimizeLabels = () => {
            const delaunay = d3.Delaunay.from(nodes, d => d.x, d => d.y);
            const voronoi = delaunay.voronoi([0, 0, dimensions.width, dimensions.height]);

            nodes.forEach((node, i) => {
                const cell = voronoi.cellPolygon(i);
                if (cell) {
                    const [cx, cy] = d3.polygonCentroid(cell);
                    const textWidth = getTextWidth(getTranslatedLabel(node.id), fontSize);
                    const textHeight = fontSize;
                    const cellBounds = {
                        left: Math.min(...cell.map(p => p[0])),
                        right: Math.max(...cell.map(p => p[0])),
                        top: Math.min(...cell.map(p => p[1])),
                        bottom: Math.max(...cell.map(p => p[1]))
                    };

                    const labelBounds = {
                        left: cx - textWidth / 2,
                        right: cx + textWidth / 2,
                        top: cy - textHeight / 2,
                        bottom: cy + textHeight / 2
                    };

                    // Adjust position if label exceeds cell boundaries
                    if (labelBounds.left < cellBounds.left) {
                        node.x = (node.x || 0) + (cellBounds.left - labelBounds.left);
                    }
                    if (labelBounds.right > cellBounds.right) {
                        node.x = (node.x || 0) - (labelBounds.right - cellBounds.right);
                    }
                    if (labelBounds.top < cellBounds.top) {
                        node.y = (node.y || 0) + (cellBounds.top - labelBounds.top);
                    }
                    if (labelBounds.bottom > cellBounds.bottom) {
                        node.y = (node.y || 0) - (labelBounds.bottom - cellBounds.bottom);
                    }
                }
            });
        };

        for (let i = 0; i < 10; i++) {
            optimizeLabels();
        }

        setEmotions(nodes.map(node => ({
            label: node.id,
            x: node.x || 0,
            y: node.y || 0,
            activation: emotions.find(e => e.label === node.id)?.activation || 0.0,
        })));

        simulationRef.current = simulation;

        return () => {
            simulation.stop();
        };
    }, [dimensions, language]);

    useEffect(() => {
        if (Object.keys(emotionalResponse).length > 0) {
            setEmotions(prevEmotions =>
                prevEmotions.map(emotion => ({
                    ...emotion,
                    activation: emotion.label === pressedEmotion
                        ? emotion.activation
                        : emotionalResponse[emotion.label] || emotion.activation,
                }))
            );
        }
    }, [emotionalResponse, pressedEmotion]);

    useEffect(() => {
        if (dimensions.width > 0 && dimensions.height > 0) {
            calculateLayout();
        }
    }, [dimensions, calculateLayout]);

    const handleInteractionStart = (emotion: Emotion) => {
        onEmotionSelected(emotion.label);
        setActiveEmotion(emotion.label);
        setPressedEmotion(emotion.label);

        const startTime = performance.now();
        const startActivation = emotion.activation;
        const duration = 2000; // 2 seconds

        const animateActivation = (currentTime: number) => {
            const elapsed = currentTime - startTime;

            if (elapsed < duration) {
                const progress = elapsed / duration;
                const newActivation = startActivation + (1 - startActivation) * (progress * progress); // Quadratic increase
                setEmotions(prevEmotions =>
                    prevEmotions.map(e => e.label === emotion.label ? {...e, activation: newActivation} : e)
                );
                animationRef.current = requestAnimationFrame(animateActivation);
            } else {
                setEmotions(prevEmotions =>
                    prevEmotions.map(e => e.label === emotion.label ? {...e, activation: 1} : e)
                );
            }
        };

        animationRef.current = requestAnimationFrame(animateActivation);

        // Set up interval for continuous callbacks
        callbackIntervalRef.current = setInterval(() => {
            onEmotionSelected(emotion.label);
        }, 1000);
    };

    const handleInteractionEnd = () => {
        setActiveEmotion(null);
        setPressedEmotion(null);

        // Stop the animation
        if (animationRef.current !== null) {
            cancelAnimationFrame(animationRef.current);
            animationRef.current = null;
        }

        // Stop the callback interval
        if (callbackIntervalRef.current !== null) {
            clearInterval(callbackIntervalRef.current);
            callbackIntervalRef.current = null;
        }
    };

    const delaunay = d3.Delaunay.from(emotions, d => d.x, d => d.y);
    const voronoi = delaunay.voronoi([0, 0, dimensions.width, dimensions.height]);

    return (
        <div ref={containerRef} style={{width: '100%', height: '100%'}}>
            <svg width="100%" height="100%" viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}>
                <defs>
                    {emotions.map((emotion, index) => (
                        <radialGradient
                            key={`gradient-${index}`}
                            id={`gradient-${index}`}
                            cx={emotion.x}
                            cy={emotion.y}
                            r={Math.sqrt((dimensions.width * dimensions.height) / emotions.length) * 0.8}
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop offset="0%" stopColor={getColor(emotion.activation)}/>
                            <stop offset="100%" stopColor="#FFFFFF"/>
                        </radialGradient>
                    ))}
                </defs>
                {emotions.map((emotion, index) => {
                    const cell = voronoi.cellPolygon(index);
                    if (!cell) return null;
                    const [cx, cy] = d3.polygonCentroid(cell);
                    const isActive = activeEmotion === emotion.label;
                    return (
                        <g key={index}>
                            <path
                                d={`M${cell.join("L")}Z`}
                                fill={animatingEmotions.has(emotion.label) || pressedEmotion === emotion.label
                                    ? getColor(emotion.activation)
                                    : `url(#gradient-${index})`}
                                stroke="black"
                                strokeWidth={isActive ? 3 : 1}
                                onMouseDown={() => handleInteractionStart(emotion)}
                                onMouseUp={handleInteractionEnd}
                                onMouseLeave={handleInteractionEnd}
                                onTouchStart={() => handleInteractionStart(emotion)}
                                onTouchEnd={handleInteractionEnd}
                                style={{
                                    cursor: pressedEmotion === emotion.label ? 'grabbing' : 'pointer',
                                }}
                            />
                            <text
                                x={cx}
                                y={cy}
                                textAnchor="middle"
                                dominantBaseline="central"
                                fontSize={fontSize}
                                fill="black"
                                style={{
                                    pointerEvents: 'none',
                                    WebkitUserSelect: 'none',
                                    MozUserSelect: 'none',
                                    msUserSelect: 'none',
                                    userSelect: 'none',
                                    touchAction: 'none',
                                }}
                            >
                                {getTranslatedLabel(emotion.label)}
                            </text>
                        </g>
                    );
                })}
            </svg>
        </div>
    );
}

export default EmotionVoronoi;