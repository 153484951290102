import {IGestureMatch} from "../../IGestureMatch";
import React, {Component} from "react";

import ISearchMatchProperties from './ISearchMatchProperties'
import {PlaybackState} from "../../types/PlaybackState";


class SearchMatch extends Component<ISearchMatchProperties, any> {

    private perc2color(perc: number) {
        let r, g, b = 0;
        if (perc < 50) {
            r = 255;
            g = Math.round(5.1 * perc);
        } else {
            g = 255;
            r = Math.round(510 - 5.10 * perc);
        }
        let h = r * 0x10000 + g * 0x100 + b * 0x1;
        return '#' + ('000000' + h.toString(16)).slice(-6);
    }

    private getYouTubeId(match: IGestureMatch) {
        let id = match.audio_id.substring(0, match.audio_id.length - 4);
        return id.substring(id.length - 11)
    }

    private isMuziekwebMatch(match: IGestureMatch) {
        return match.audio_id.indexOf("-") > 0 && match.audio_id.indexOf("/WAV/") !== -1
    }

    private getMuziekwebId(match: IGestureMatch) {
        const rawPath = match.audio_id;
        const path = rawPath.split("-")[0].split("/");
        const id = path[path.length - 1];
        return id;
    }

    private fmtMSS(s: number) {
        return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s
    }

    private onPlayGestureMatch(match: IGestureMatch) {
        console.log("onPlayGestureMatch");

        if (this.isMuziekwebMatch(match)) {
            console.log("not a YT match");
            return;
        }

        this.setState({gestureMatchResultPlaybackState: PlaybackState.PLAYING});

        console.log("got gesture match", match, match.audio_id);

        const youTubeId = this.getYouTubeId(match);

        console.log("offset", Math.round(match.offset));

        const searchLatency = new Date().getTime() / 1000 - match.request_time;
        const currentRecordingPosition = match.offset + match.context_duration + searchLatency;

        this.props.onPlayGestureMatch(youTubeId, match.request_time, currentRecordingPosition);
    }

    render() {

        const ytThumbType = this.props.fractions < 0.25 ? 'default' : 'hqdefault';
        const mzwThumbType = this.props.fractions < 0.5 ? 'MEDIUM' : 'LARGE';

        const ytThumbnailUrl = "https://i1.ytimg.com/vi/" + this.getYouTubeId(this.props.match) + "/" + ytThumbType + ".jpg";
        const muziekwebUrl = "https://media.cdr.nl/COVER/" + mzwThumbType + "/FRONT/" + this.getMuziekwebId(this.props.match) + ".jpg";

        const recordingId = this.isMuziekwebMatch(this.props.match) ? this.getMuziekwebId(this.props.match) : this.getYouTubeId(this.props.match);

        const imageUrl = this.isMuziekwebMatch(this.props.match) ? muziekwebUrl : ytThumbnailUrl;
        const imgSide = "calc(" + Math.min(45, Math.round(this.props.fractions * 100)) + "vmin - 4px)";
        const imgContainerSide = Math.min(45, Math.round(this.props.fractions * 100)) + "vw";

        const searchLatency = new Date().getTime() / 1000 - this.props.match.request_time;
        const currentRecordingPosition = this.props.match.offset + this.props.match.context_duration + searchLatency;

        const minSec = this.fmtMSS(Math.round(currentRecordingPosition));
        const rad = this.props.match.specificity / 3 + 2;
        return (
            <div style={{flexDirection: "column", width: imgContainerSide, marginBottom: 30}}>
                <div>
                    {minSec}
                </div>
                <div>
                    <img src={imageUrl} alt={"play button"} style={{width: imgSide, height: imgSide}}
                         onClick={this.onPlayGestureMatch.bind(this, this.props.match)}/>
                </div>
                <div style={{fontSize: "13px", position: 'relative'}}>
                    {Math.round(this.props.match.distance * 10) / 10}
                    /
                    {Math.round(this.props.match.specificity * 100) / 100}
                </div>
                <div style={{fontSize: "10px"}}>
                    {Math.round((searchLatency * 10) / 10)}
                </div>
                <div style={{fontSize: "7px"}}>
                    {recordingId}
                </div>
                <div style={{fontSize: "7px"}}>
                    {this.props.match.context_duration} sec
                </div>
                <div style={{position: "relative"}}>
                    <div style={{
                        backgroundColor: this.perc2color(100 - this.props.match.distance),
                        width: rad,
                        height: rad,
                        borderRadius: rad,
                        position: 'absolute',
                        opacity: 1 - this.props.match.distance / 100,
                        top: 5,
                        left: "calc(" + imgContainerSide + " / 2 - " + Math.round(rad / 2) + "px)"
                    }}>
                    </div>
                </div>
            </div>
        );
    }

}

export default SearchMatch;