import React, { useState } from 'react';
import './ControlButton.css';
import { FaMicrophone, FaEye } from "react-icons/fa";

interface Props {
    onShowPressed: (touched: boolean) => void;
    onTellPressed: (touched: boolean) => void;
}

const ControlButton: React.FC<Props> = ({ onShowPressed, onTellPressed }) => {
    const [touched, setTouched] = useState(false);

    const handlePress = (isShow: boolean) => {
        setTouched(true);
        if (isShow) {
            onShowPressed(true);
        } else {
            onTellPressed(true);
        }
    };

    const handleRelease = (isShow: boolean) => {
        setTouched(false);
        if (isShow) {
            onShowPressed(false);
        } else {
            onTellPressed(false);
        }
    };

    const borderColor = '#D3D3D3'; // Light gray color

    return (
        <div className='Start-main'
             style={{
                 backgroundColor: touched ? "navajowhite" : "ghostwhite",
                 position: 'relative',
                 borderRadius: '50%',
                 border: `2px solid ${borderColor}`,
                 display: 'flex',
                 flexDirection: 'column',
                 alignItems: 'center',
                 justifyContent: 'center',
                 width: '120px',
                 height: '120px',
             }}
        >
            <div style={{
                position: 'absolute',
                top: '0',
                left: '50%',
                height: '100%',
                width: '0',
                borderLeft: `2px dashed ${borderColor}`,
            }} />
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "100%",
            }}>
                <div style={{
                    width: '50%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                     onTouchStart={() => handlePress(true)}
                     onMouseDown={() => handlePress(true)}
                     onMouseUp={() => handleRelease(true)}
                     onMouseLeave={() => handleRelease(true)}
                     onTouchEnd={() => handleRelease(true)}
                     onTouchCancel={() => handleRelease(true)}
                >
                    <FaEye size="40px" color="black"/>
                    <div style={{marginTop: '5px', fontSize: '16px'}}>Show</div>
                </div>
                <div style={{
                    width: '50%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                     onTouchStart={() => handlePress(false)}
                     onMouseDown={() => handlePress(false)}
                     onMouseUp={() => handleRelease(false)}
                     onMouseLeave={() => handleRelease(false)}
                     onTouchEnd={() => handleRelease(false)}
                     onTouchCancel={() => handleRelease(false)}
                >
                    <FaMicrophone size="40px" color="black"/>
                    <div style={{marginTop: '5px', fontSize: '16px'}}>Tell</div>
                </div>
            </div>
        </div>
    )
}

export default ControlButton;